import React, { FC } from 'react'
import { Link } from 'gatsby'

import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { inszuAppURL } from '../config'

interface MoreCompleteProtectionProps {}

const MoreCompleteProtection: FC<MoreCompleteProtectionProps> = () => {
  return (
    <main>
      <Header />
      <div className="container px-4 mx-auto">
        <div className="flex flex-col py-4 lg:flex-row-reverse lg:py-10">
          <section className="mb-8 lg:flex-1 lg:ml-8">
            <div className="embed-container">
              <iframe
                title="Complete protection"
                src="https://player.vimeo.com/video/713108987"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </section>
          <article className="lg:flex-1">
            <div className="mb-4 text-sm tracking-widest uppercase">learn more</div>
            <h1 className="mb-6 text-2xl font-bold leading-relaxed lg:mb-8 lg:text-4xl text-slate-900">
              More Complete
              <br />
              protection
            </h1>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Here at Inszu, we believe that life insurance should not only financially protect you and your loved ones
              in the event you were to pass away but also provide living benefits while you are still alive. With cancer
              being one of the leading causes of death in young adulthood, we’ve made it our top priority to ensure that
              you can provide a lump sum payment if diagnosed. In fact, if you have any of the following illnesses you
              will be completely protected and receive up to 100% of your tax-free death benefit:
            </p>
            <ul className="pl-4 mb-8 list-disc lg:mb-10">
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Heart Attack</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Stroke</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Cancer</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Blindness</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Terminal Illness</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">
                HIV contracted performing duties as a professional healthcare worker
              </li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Kidney Failure</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Paralysis</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Major Organ Transplant Surgery</li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">Coronary Artery Bypass Graft (10%)</li>
            </ul>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Having living benefits attached to your life insurance plan is a good way to add to your protection. Think
              of it this way, what would happen if you had a heart attack /stroke or you went in to see your doctor for
              a check-up and all of a sudden you were told that you were diagnosed with cancer? Are you financially
              prepared to pay for all of the hospital medical bills? With more and more people today experiencing these
              critical illnesses, owning a life insurance policy with added living benefits that offsets unexpected
              expenses is what life insurance is truly about.
            </p>
            <Link
              to={inszuAppURL}
              className="px-6 py-4 text-white transition bg-orange-600 rounded-lg appearance-none motion-reduce:transition-none hover:bg-orange-700"
            >
              Get Started
            </Link>
          </article>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default MoreCompleteProtection
